import React, { useState } from 'react'

import { Helmet } from 'react-helmet'

import './signup.css'
import { ToastContainer, toast } from 'react-toastify';

const Signup = (props) => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [discordUsername, setDiscordUsername] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState()
  const [userSecurityKey, setUserSecurityKey] = useState("");
  const [adminSecurityKey, setAdminSecurityKey] = useState("");


  function handleSubmit(e) {
    if (userType == "Admin" && adminSecurityKey != '1089131120697209292006') {
      e.preventDefault();
      toast.error('Invalid Admin Security Key | Please Contact System Administrator!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else if (userType == "User" && userSecurityKey != "10891311206972") {
      e.preventDefault();
      toast.error('Invalid User Security Key | Please Contact System Administrator!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {
      e.preventDefault();

      console.log(fname, lname, discordUsername, email, password, userType);
      fetch("https://daiki-login-api.vercel.app/register", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          fname,
          email,
          discordUsername,
          lname,
          password,
          userType
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          //console.log(data, "userRegister");
          if (data.status == "ok") {
            /* alert("Registration Successful"); */
            toast.success('Registration Successful!', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            /*  window.location.href = '/' */
          } else if (data.error === 'User Exists') {
            toast.error('User Already Exists | Please Contact your System Administrator', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          } else {
            toast.error('Something Went Wrong', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
        });
    }
  }

  return (
    <div className="signup-container">
      <form className="signup-form">
        <div className="signup-container1">
          <h1 className="signup-text">
            <span>Signup</span>
            <br></br>
          </h1>
          <div className="signup-container2">
            <span className="signup-registertext">
              <span>Register As</span>
              <br></br>
            </span>
            <span className="signup-registertext1">
              <span>User</span>
              <br></br>
            </span>
            <input
              type="radio"
              name="UserType"
              value="User"
              className="signup-radiobutton"
              onChange={((e) => setUserType(e.target.value))}
            />
            <span className="signup-registertext2">
              <span>Admin</span>
              <br></br>
            </span>
            <input
              type="radio"
              name="UserType"
              value="Admin"
              className="signup-radiobutton1"
              onChange={((e) => setUserType(e.target.value))}
            />
          </div>
          {userType == "User" ? (
            <div>
              <span className="signup-uskey">
                <span>User Security Key</span>
                <br></br>
              </span>
              <input
                type="password"
                required
                placeholder="User Security Key"
                className="signup-uskey-input input"
                onChange={(e) => setUserSecurityKey(e.target.value)}
              />
            </div>
          ) : null}
          {userType == "Admin" ? (
            <div>
              <span className="signup-askey">
                <span>Admin Security Key</span>
                <br></br>
              </span>
              <input
                type="password"
                required
                placeholder="Admin Security Key"
                className="signup-askey-input input"
                onChange={(e) => setAdminSecurityKey(e.target.value)}
              />
            </div>
          ) : null}
          {/* <span className="signup-skey">
            <span>Security Key</span>
            <br></br>
          </span>
          <input
            type="password"
            placeholder="Security Key"
            className="signup-skey-input input"
            onChange={(e) => setSecurityKey(e.target.value)}
          /> */}
          <span className="signup-fname">
            <span>First Name</span>
            <br></br>
          </span>
          <input
            type="text"
            required
            placeholder="First Name"
            className="signup-fname-input input"
            onChange={(e) => setFname(e.target.value)}
          />
          <span className="signup-lname">
            <span>Last Name</span>
            <br></br>
          </span>
          <input
            type="text"
            required
            placeholder="Last Name"
            className="signup-lname-input input"
            onChange={(e) => setLname(e.target.value)}
          />
          <span className="signup-discordUsername">
            <span>Discord Username</span>
            <br></br>
          </span>
          <input
            type="text"
            required
            placeholder="Discord Username"
            className="signup-discordUsername-input input"
            onChange={(e) => setDiscordUsername(e.target.value)}
          />
          <span className="signup-email">Email Address</span>
          <input
            type="email"
            required
            placeholder="Enter Email Address"
            className="signup-email-input input"
            onChange={(e) => setEmail(e.target.value)}
          />
          <span className="signup-password">Password</span>
          <input
            type="password"
            required
            placeholder="Enter Password"
            className="signup-password-input input"
            onChange={(e) => setPassword(e.target.value)}
          />
          {userType == "Admin" || userType == "User" ? (
            <button type="submit" className="signup-signup button" onClick={handleSubmit}>
              <span>
                <span>Signup</span>
                <br></br>
              </span>
            </button>
          ) : null}
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}

export default Signup
