import React, { Component, useEffect, useState } from "react";
import AdminHome from "./admin-home";

import UserHome from "./user-home";
import Login from "./login";
import CreatePost from "./create-posts";
import { ToastContainer, toast } from "react-toastify";

export default function createPostRoutes() {
    const [userData, setUserData] = useState("");
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        fetch("https://daiki-login-api.vercel.app/userData", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                token: window.localStorage.getItem("token"),
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                console.log(data, "userData");
                if (data.data.userType == "Admin") {
                    setAdmin(true);
                }

                if (data.data.userType == "User") {
                    toast.error('You don\'t have Admin Permissions to see this!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }

                setUserData(data.data);

                if (data.data == "token expired") {
                    //alert("Token expired login again");
                    window.localStorage.clear();
                    return window.location.href = "/login";
                }
            });
    }, []);

    return (
        <div>
            {admin ? <CreatePost userData={userData} /> : <UserHome userData={userData} />}
            <ToastContainer />
        </div>)
}
