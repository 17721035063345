import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { Helmet } from 'react-helmet'

import './login.css';

export default function Login() {

  const showToast = () => {
    toast("Toast Example");
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    console.log(email, password);
    fetch("https://daiki-login-api.vercel.app/login-user", {
      method: "POST",
      crossDomain: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to fetch!');
        }
        return res.json();
      })
      .then((data) => {
        console.log(data, "userRegister");
        if (data.status === "ok") {
          toast.success('Login Successful! Transfering To New Page!', {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
          setTimeout(() => {
            window.location.href = "https://daiki-admin-dashboard.vercel.app/home";
            window.localStorage.setItem("token", data.data);
            window.localStorage.setItem("loggedIn", true);
          }, 2500);
        } else if (data.error === 'Invalid Password') {
          toast.warn('Invalid Password', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        } else {
          toast.error('User Not Found!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error('Unable to login at this time. Please try again | If you continue to see this message please contact a system admin', {
          position: "top-right",
          autoClose: 7500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setLoading(false);
        console.log(error);
      });
  }
  return (
    <div className="login-container">
      <Helmet>
        <title>Login - Daiki Admin Dashboard</title>
        <meta property="og:title" content="Login - Daiki Admin Dashboard" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7a9fd67-f83a-4c10-9db3-9387e93b763c/a992bd6c-5d4c-41f0-8cd3-c8bafe79cd24?org_if_sml=1"
        />
      </Helmet>
      <form className="login-form">
        <div className="login-container1">
          <h1 className="login-text">
            <span>Login</span>
            <br></br>
          </h1>
          <span className="login-text3">Email Address</span>
          <input
            type="email"
            placeholder="Enter Email"
            autoComplete="email"
            required
            onChange={(e) => setEmail(e.target.value)}
            className="login-textinput input"
          />
          <span className="login-text4">Password</span>
          <input
            type="password"
            placeholder="Enter Password"
            required
            onChange={(e) => setPassword(e.target.value)}
            className="login-textinput1 input"
          />
          <button type="submit" className="login-button button" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Logging in...' : "Login"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}