import React from 'react'
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types'

import './header.css'

const Header = (props) => {
  function Logout() {
    window.localStorage.clear();
    window.location.href = '/';
  }
  return (
    <header
      data-role="Header"
      className={`header-header ${props.rootClassName} `}
    >
      <img
        alt={props.image_alt}
        src={props.image_src}
        className="header-image"
      />
      <div className="header-nav">
        <nav className="header-nav1">
          <Link to="/" className="header-navlink">
            {props.Home}
          </Link>
        </nav>
        <nav className="header-nav2">
          <Link to="/updates" className="header-navlink1">
            {props.Updates}
          </Link>
        </nav>
      </div>
      <div className="header-btn-group">
        <button className="header-login button" type='submit' onClick={Logout}>{props.Logout}</button>
      </div>
    </header>
  )
}

Header.defaultProps = {
  Register: 'Register',
  rootClassName: '',
  Logout: 'Logout',
  text3: 'Team',
  image_src: '/playground_assets/copy_of_animated_logo-200h.png',
  image_alt1: 'image',
  image_src1:
    'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  text2: 'Pricing',
  Home: 'Home',
  text4: 'Blog',
  image_alt: 'logo',
  text1: 'Features',
  rootClassName1: '',
  Updates: 'Updates',
}

Header.propTypes = {
  Register: PropTypes.string,
  rootClassName: PropTypes.string,
  Logout: PropTypes.string,
  text3: PropTypes.string,
  image_src: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src1: PropTypes.string,
  text2: PropTypes.string,
  Home: PropTypes.string,
  text4: PropTypes.string,
  image_alt: PropTypes.string,
  text1: PropTypes.string,
  rootClassName1: PropTypes.string,
  Updates: PropTypes.string,
}

export default Header
