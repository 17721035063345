import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import { Helmet } from 'react-helmet'

import './create-posts.css';
import Header from '../components/header';
import Footer from '../components/footer-container'
import AdminHeader from '../components/admin-header';

export default function CreatePostCopy({ userData }) {

    const showToast = () => {
        toast("Toast Example");
    }

    const [botTitle, setBotTitle] = useState("");
    const [weekTitle, setWeekTitle] = useState("");
    const [botDescription, setBotDescription] = useState("");
    const [weekDescription, setWeekDescription] = useState("");
    const [discordUsername, setDiscordUsername] = useState("");

    const [manageType, setManageType] = useState("");
    const [botPostID, setBotPostID] = useState("");
    const [weekPostID, setWeekPostID] = useState("");

    //console.log(userData.discordUsername)



    function handleBotSubmit(e) {
        e.preventDefault();
        const ID = Math.floor(100000 + Math.random() * 900000)
        fetch("https://daiki-login-api.vercel.app/add-botPost", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                botPostTitle: botTitle,
                botPostDescription: botDescription,
                botPostAuthor: userData.discordUsername,
                botPostID: ID
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status == "ok") {
                    /* alert("Login Successful | Transfering you now!"); */
                    toast.success(`Created Post Successfully | Post ID: ${ID}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setBotTitle("")
                    setBotDescription("")
                } else {
                    toast.error('An Error has Occurred', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setBotTitle("")
                    setBotDescription("")
                    /* alert("User Not Found! Please contact your system administrator") */
                }
            });
    }

    function handleWeekSubmit(e) {
        e.preventDefault();
        const ID = Math.floor(100000 + Math.random() * 900000)
        fetch("https://daiki-login-api.vercel.app/add-weekPost", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                weekPostTitle: weekTitle,
                weekPostDescription: weekDescription,
                weekPostAuthor: userData.discordUsername,
                weekPostID: ID
            }),
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status == "ok") {
                    /* alert("Login Successful | Transfering you now!"); */
                    toast.success(`Created Post Successfully | Post ID: ${ID}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setWeekTitle("")
                    setWeekDescription("")
                } else {
                    toast.error('An Error has Occurred', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setWeekTitle("")
                    setWeekDescription("")
                    /* alert("User Not Found! Please contact your system administrator") */
                }
            });
    }

    function handleBotDeleteSubmit(e) {
        e.preventDefault();
        fetch("https://daiki-login-api.vercel.app/delete-botPost", {
            method: "DELETE",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                botPostID: botPostID,
            })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status == "ok") {
                    /* alert("Login Successful | Transfering you now!"); */
                    toast.success(`Deleted Post Successfully | Post ID: ${botPostID}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setBotPostID("")
                } else {
                    toast.error('An Error has Occurred', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setBotPostID("")
                    /* alert("User Not Found! Please contact your system administrator") */
                }
            });
    }

    function handleWeekDeleteSubmit(e) {
        e.preventDefault();
        fetch("https://daiki-login-api.vercel.app/delete-weekPost", {
            method: "DELETE",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                weekPostID: weekPostID,
            })
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status == "ok") {
                    /* alert("Login Successful | Transfering you now!"); */
                    toast.success(`Deleted Post Successfully | Post ID: ${weekPostID}`, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setWeekPostID("")
                } else {
                    toast.error('An Error has Occurred', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    setWeekPostID("")
                    /* alert("User Not Found! Please contact your system administrator") */
                }
            });
    }

    return (
        <div className="create-post-container">
            <head>
                <Helmet>
                    <title>Create Post - Daiki Admin Dashboard</title>
                    <meta property="og:title" content="Create Post - Daiki Admin Dashboard" />
                    <meta
                        property="og:image"
                        content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7a9fd67-f83a-4c10-9db3-9387e93b763c/a992bd6c-5d4c-41f0-8cd3-c8bafe79cd24?org_if_sml=1"
                    />
                </Helmet>
            </head>
            <AdminHeader rootClassName="header-root-class-name2"></AdminHeader>
            <div className="create-post-management-type-container">
                <h1 className="create-post-manage-heading">
                    <span>Management Type</span>
                    <br></br>
                </h1>
                <div className="create-post-container1">
                    <span className="create-post-manage-type-create">
                        <span>Create Post</span>
                        <br></br>
                    </span>
                    <input
                        type="radio"
                        name="ManageType"
                        value="Create"
                        className="create-post-create-radio"
                        onChange={((e) => setManageType(e.target.value))}
                    />
                    <span className="create-post-manage-type-delete">
                        <span>Delete Post</span>
                        <br></br>
                    </span>
                    <input
                        type="radio"
                        name="ManageType"
                        value="Delete"
                        className="create-post-delete-radio"
                        onChange={((e) => setManageType(e.target.value))}
                    />
                </div>
            </div>
            {manageType == "Create" ? (
                <div className="create-post-delete-posts">
                    <div className="create-post-delete-bot">
                        <form className="create-post-form">
                            <div className="create-post-container2">
                                <h1 className="create-post-text06">
                                    <span>Bot Updates</span>
                                    <br></br>
                                </h1>
                                <span className="create-post-fname">
                                    <span>Post Title</span>
                                    <br></br>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Post Title"
                                    className="create-post-fname-input input"
                                    onChange={(e) => setBotTitle(e.target.value)}
                                    value={botTitle}
                                />
                                <span className="create-post-fname1">
                                    <span>Post Description</span>
                                    <br></br>
                                </span>
                                <textarea type="text" style={{ width: 260 }} value={botDescription} className="create-post-fname-input1 input" onChange={(e) => setBotDescription(e.target.value)} />
                                <button type="submit" className="create-post-signup button" onClick={handleBotSubmit}>
                                    <span>
                                        <span>Submit</span>
                                        <br></br>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="create-post-delete-week">
                        <form className="create-post-form1">
                            <div className="create-post-container3">
                                <h1 className="create-post-text16">
                                    <span>Week Updates</span>
                                    <br></br>
                                </h1>
                                <span className="create-post-fname2">
                                    <span>Post Title</span>
                                    <br></br>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Post Title"
                                    className="create-post-fname-input2 input"
                                    onChange={(e) => setWeekTitle(e.target.value)}
                                    value={weekTitle}
                                />
                                <span className="create-post-fname3">
                                    <span>Post Description</span>
                                    <br></br>
                                </span>
                                <textarea type="text" style={{ width: 260 }} value={weekDescription} className="create-post-fname-input1 input" onChange={(e) => setWeekDescription(e.target.value)} />
                                <button type="submit" className="create-post-signup1 button" onClick={handleWeekSubmit}>
                                    <span>
                                        <span>Submit</span>
                                        <br></br>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
            {manageType == "Delete" ? (
                <div className="create-post-delete-posts1">
                    <div className="create-post-delete-bot1">
                        <form className="create-post-form2">
                            <div className="create-post-container4">
                                <h1 className="create-post-text26">
                                    <span>Delete Bot Post</span>
                                    <br></br>
                                </h1>
                                <span className="create-post-fname4">
                                    <span>Post ID</span>
                                    <br></br>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Post ID"
                                    value={botPostID}
                                    className="create-post-fname-input4 input"
                                    onChange={(e) => setBotPostID(e.target.value)}
                                />
                                <button type="submit" className="create-post-signup2 button" onClick={handleBotDeleteSubmit}>
                                    <span>
                                        <span>Submit</span>
                                        <br></br>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="create-post-delete-week1">
                        <form className="create-post-form3">
                            <div className="create-post-container5">
                                <h1 className="create-post-text34">
                                    <span>Delete Week Post</span>
                                    <br></br>
                                </h1>
                                <span className="create-post-fname5">
                                    <span>Post ID</span>
                                    <br></br>
                                </span>
                                <input
                                    type="text"
                                    placeholder="Post ID"
                                    value={weekPostID}
                                    className="create-post-fname-input5 input"
                                    onChange={(e) => setWeekPostID(e.target.value)}
                                />
                                <button type="submit" className="create-post-signup3 button" onClick={handleWeekDeleteSubmit}>
                                    <span>
                                        <span>Submit</span>
                                        <br></br>
                                    </span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
        </div>
    )
}