import React, { useState, useEffect } from 'react'
import axios from 'axios';

import { Helmet } from 'react-helmet'

import './updates.css'
import Footer from '../components/footer-container'
import Header from '../components/header'

const Updates = () => {
  const [botData, setBotData] = useState([]);
  const [weekData, setWeekData] = useState([]);
  const [currentBotPage, setCurrentBotPage] = useState(1);
  const [currentWeekPage, setCurrentWeekPage] = useState(1);
  const [postsPerPage] = useState(5);

  useEffect(() => {
    axios.get('https://daiki-login-api.vercel.app/get-botPosts')
      .then(response => {
        console.log(response.data.data, "BotDATA");
        setBotData(response.data.data);
      })

    axios.get('https://daiki-login-api.vercel.app/get-weekPosts')
      .then(response => {
        console.log(response.data.data, "WeekDATA");
        setWeekData(response.data.data);
      })
  }, []);

  const sortedBotData = botData.sort((a, b) => new Date(b.botPostTimestamp) - new Date(a.botPostTimestamp));
  const sortedWeekData = weekData.sort((a, b) => new Date(b.weekPostTimestamp) - new Date(a.weekPostTimestamp));

  // Get current posts
  const indexOfLastBotPost = currentBotPage * postsPerPage;
  const indexOfFirstBotPost = indexOfLastBotPost - postsPerPage;
  const currentBotPosts = sortedBotData.slice(indexOfFirstBotPost, indexOfLastBotPost);

  const indexOfLastWeekPost = currentWeekPage * postsPerPage;
  const indexOfFirstWeekPost = indexOfLastWeekPost - postsPerPage;
  const currentWeekPosts = sortedWeekData.slice(indexOfFirstWeekPost, indexOfLastWeekPost);

  // Change page
  const botPaginate = (pageNumber) => {
    setCurrentBotPage(pageNumber);
  };
  const weekPaginate = (pageNumber) => {
    setCurrentWeekPage(pageNumber);
  };

  return (
    <div className="updates-container">
      <Helmet>
        <title>Updates - Daiki Admin Dashboard</title>
        <meta property="og:title" content="Updates - Daiki Admin Dashboard" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7a9fd67-f83a-4c10-9db3-9387e93b763c/c6a2d365-41fa-4d0d-a6ac-836e2adf234c?org_if_sml=1"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name1"></Header>
      <div className="updates-daiki-bot-updates">
        <h1 className="updates-text">
          <span>Daiki Bot Updates</span>
          <br></br>
        </h1>
        {currentBotPosts.length === 0 ? (
          <div className="updates-container1">
            <div className="updates-post">
              <div className="updates-container2">
                <h1 className="updates-bot-post-title">No Posts Found</h1>
                <div className="updates-container3">
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {currentBotPosts.map((post) => (
          <div className="updates-container1">
            <div className="updates-post">
              <div className="updates-container2">
                <h1 className="updates-bot-post-title">{post.botPostTitle}</h1>
                <span className="updates-bot-post-description">
                  {post.botPostDescription.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </span>
                <div className="updates-container3">
                  <div className="updates-profile">
                    <span className="updates-bot-post-author">
                      <span>{post.botPostAuthor}</span>
                      <br />
                    </span>
                  </div>
                  <div className="updates-container8">
                    <span className="updates-bot-post-id1">
                      <span>Post ID: {post.botPostID}</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* Pagination */}
        {sortedBotData.length > postsPerPage && (
          <div className="pagination-container">
            {Array.from({ length: Math.ceil(sortedBotData.length / postsPerPage) }, (_, i) => (
              <button
                key={i}
                className={`pagination-button ${currentBotPage === i + 1 ? 'active' : ''}`}
                onClick={() => botPaginate(i + 1)}
                style={{ color: "white", fontWeight: "bold" }}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      <div className="updates-blog">
        <h1 className="updates-text07">
          <span>Weekly Updates</span>
          <br></br>
        </h1>
        {currentWeekPosts.length === 0 ? (
          <div className="updates-container1">
            <div className="updates-post">
              <div className="updates-container2">
                <h1 className="updates-bot-post-title">No Posts Found</h1>
                <div className="updates-container3">
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {currentWeekPosts.map((post) => (
          <div className="updates-container1">
            <div className="updates-post">
              <div className="updates-container2">
                <h1 className="updates-bot-post-title">{post.weekPostTitle}</h1>
                <span className="updates-bot-post-description">
                  {post.weekPostDescription.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      <span>{line}</span>
                      <br />
                    </React.Fragment>
                  ))}
                </span>
                <div className="updates-container3">
                  <div className="updates-profile">
                    <span className="updates-bot-post-author">
                      <span>{post.weekPostAuthor}</span>
                      <br />
                    </span>
                  </div>
                  <div className="updates-container8">
                    <span className="updates-bot-post-id1">
                      <span>Post ID: {post.weekPostID}</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* Pagination */}
        {sortedWeekData.length > postsPerPage && (
          <div className="pagination-container">
            {Array.from({ length: Math.ceil(sortedWeekData.length / postsPerPage) }, (_, i) => (
              <button
                key={i}
                className={`pagination-button ${currentWeekPage === i + 1 ? 'active' : ''}`}
                onClick={() => weekPaginate(i + 1)}
                style={{ color: "white", fontWeight: "bold" }}
              >
                {i + 1}
              </button>
            ))}
          </div>
        )}
      </div>
      <Footer />
    </div>
  )
}


export default Updates
