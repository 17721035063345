import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import './style.css'
import Signup from './views/signup'
import Updates from './views/updateRouter'
import Login from './views/login'
import AdminHome from './views/admin-home'
import UserHome from './views/user-home'
import UserDetails from './views/userDetails'
import CreatePost from './views/createPostRoutes.js'

const App = () => {
  function isLoggedIn() {
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    console.log("isLoggedIn:", isLoggedIn);
    return isLoggedIn;
  }

  return (
    <Router>
      <div>
        <Route
          exact
          path="/"
          render={() => {
            return isLoggedIn() ? <UserDetails /> : <Login />;
          }}
        />
        <Route
          exact
          path="/updates"
          render={() => {
            return isLoggedIn() ? <Updates /> : <Login />;
          }}
        />
        <Route
          exact
          path="/create-post"
          render={() => {
            return isLoggedIn() ? <CreatePost /> : <Login />;
          }} />
        <Route component={Signup} exact path="/signup" />
        <Route component={Login} exact path="/login" />
        <Route component={UserDetails} exact path="/home" />
      </div>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
