import React from 'react'

import { Helmet } from 'react-helmet'

import Header from '../components/header'
import Footer from '../components/footer-container'
import './user-home.css'

export default function UserHome({ userData }) {
  return (
    <div className="user-home-container">
      <Helmet>
        <title>Daiki Admin Dashboard</title>
        <meta property="og:title" content="Daiki Admin Dashboard" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7a9fd67-f83a-4c10-9db3-9387e93b763c/89d6e3f5-ffc0-44ca-a9d5-50ae9ca3b42c?org_if_sml=1"
        />
      </Helmet>
      <Header rootClassName="header-root-class-name"></Header>
      <div className="user-home-welcome-container">
        <h1 className="user-home-text">Welcome Back {userData.fname}!</h1>
      </div>
      <div className="user-home-container1">
        <div className="user-home-container2">
          <h1 className="user-home-text01">
            <span>Upcoming Updates</span>
            <br></br>
          </h1>
          <div className="user-home-separator"></div>
          <span className="user-home-text04">
            <span>Daiki V14.4</span>
            <br></br>
          </span>
          <span className="user-home-text07">
            No Available Information at this time
          </span>
        </div>
        <div className="user-home-container3">
          <h1 className="user-home-text08">
            <span>Weekly Update</span>
            <br></br>
          </h1>
          <div className="user-home-separator1"></div>
          <span className="user-home-text11">
            <span className="user-home-text12">No Available Information at this time</span>
            {/* <span className="user-home-text12">Week of 05/8/23</span>
            <br className="user-home-text13"></br>
            <br></br>
            <span className="user-home-text15">Key</span>
            <br></br>
            <span>* = Completed</span>
            <br></br>
            <span>~ = In Progress</span>
            <br></br>
            <br></br>
            <span>Slowmode ~ (Started on 5/22/23 @ 11:20 PM)</span>
            <br></br>
            <span>Emoji Id</span>
            <br></br>
            <span>8-Ball ~ (Started on 5/22/23 @ 11:20 PM)</span>
            <br></br>
            <span>Heads or Tails &quot;Coin Flip&quot;</span>
            <br></br>
            <span>Stats (Shows Bot Stats)</span>
            <br></br>
            <br></br>
            <span className="user-home-text33">Daiki Only Commands</span>
            <br className="user-home-text34"></br>
            <span>Post *</span>
            <br></br>
            <span>    ∙ create-bot (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ create-week (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ delete-bot (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ delete-week (Completed 5/3/23)</span>
            <br></br>
            <br></br>
            <span className="admin-home-text38">Daiki Website</span>
            <br className="admin-home-text39"></br>
            <br></br>
            <span>    ∙ Create Limit to Posts shows</span>
            <br></br>
            <span>    ∙ Create Create Delete Function for posts (ADMIN ONLY)</span>
            <br></br> */}
          </span>
        </div>
      </div>
      <Footer />
    </div>
  )
}
