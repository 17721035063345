import React, { useState, useRef, useEffect } from 'react'

import { Helmet } from 'react-helmet'

import AdminHeader from '../components/admin-header'
import Footer from '../components/footer-container'
import './admin-home.css'

export default function UserHome({ userData }) {
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);
  const currentUserPage = useRef();
  const [isLoading, setLoading] = useState("");



  useEffect(() => {
    currentUserPage.current = 1;
    // getAllUser();
    getPaginatedUsers();
  }, []);


  //fetching all user
  const getAllUser = () => {
    fetch("https://daiki-login-api.vercel.app/getAllUser", {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userData");
        setData(data.data);
      });
  };



  //logout
  const logOut = () => {
    window.localStorage.clear();
    window.location.href = "./sign-in";
  };


  //deleting user
  const deleteUser = (id, name) => {
    if (window.confirm(`Are you sure you want to delete ${name}`)) {
      fetch("https://daiki-login-api.vercel.app/deleteUser", {
        method: "POST",
        crossDomain: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({
          userid: id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          alert(data.data);
          getAllUser();
        });
    } else {
    }
  };

  //pagination
  function handlePageClick(e) {
    console.log(e);
    currentUserPage.current = e.selected + 1;
    getPaginatedUsers();


  }
  function changeLimit() {
    currentUserPage.current = 1;
    getPaginatedUsers();
  }

  function getPaginatedUsers() {
    fetch(`https://daiki-login-api.vercel.app/paginatedUsers?page=${currentUserPage.current}&limit=${limit}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data, "userData");
        setPageCount(data.pageCount);
        setData(data.result)


      });

  }

  /* useEffect(() => {
    axios.get('https://daiki-login-api.vercel.app/get-weekPosts')
      .then(response => {
        console.log(response.data.data, "WeekDATA");
        setWeekData(response.data.data);
      })

  }, [])

  const sortedWeekData = weekData.sort((a, b) => new Date(b.weekPostTimestamp) - new Date(a.weekPostTimestamp)); */



  return (
    <div className="user-home-container">
      <Helmet>
        <title>Daiki Admin Dashboard</title>
        <meta property="og:title" content="Daiki Admin Dashboard" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/b7a9fd67-f83a-4c10-9db3-9387e93b763c/89d6e3f5-ffc0-44ca-a9d5-50ae9ca3b42c?org_if_sml=1"
        />
      </Helmet>
      <AdminHeader></AdminHeader>
      <div className="admin-home-welcome-container">
        <h1 className="admin-home-text">
          Welcome Back to the Admin Page {userData.fname}!
        </h1>
      </div>
      <div className="admin-home-container1">
        <div className="admin-home-container2">
          <h1 className="admin-home-text01">
            <span>Manage Users</span>
            <br></br>
          </h1>
          <div className="admin-home-separator"></div>
          {data.map((i) => {
            return (
              <div className="admin-home-information">
                <span className="admin-home-text04">
                  <span>{i.fname}</span>
                  <br></br>
                </span>
                <div className="admin-home-separator1"></div>
                <span className="admin-home-text07">
                  <span>{i.email}</span>
                  <br></br>
                </span>
                <div className="admin-home-separator2"></div>
                <span className="admin-home-text10">
                  <span>{i.userType}</span>
                  <br></br>
                </span>
                <div className="admin-home-separator3"></div>
                <svg viewBox="0 0 1024 1024" className="admin-home-icon" onClick={() => deleteUser(i._id, i.fname)}>
                  <path d="M768 298.667v554.667c0 11.776-4.736 22.4-12.501 30.165s-18.389 12.501-30.165 12.501h-426.667c-11.776 0-22.4-4.736-30.165-12.501s-12.501-18.389-12.501-30.165v-554.667zM725.333 213.333v-42.667c0-35.328-14.379-67.413-37.504-90.496s-55.168-37.504-90.496-37.504h-170.667c-35.328 0-67.413 14.379-90.496 37.504s-37.504 55.168-37.504 90.496v42.667h-170.667c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667h42.667v554.667c0 35.328 14.379 67.413 37.504 90.496s55.168 37.504 90.496 37.504h426.667c35.328 0 67.413-14.379 90.496-37.504s37.504-55.168 37.504-90.496v-554.667h42.667c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667zM384 213.333v-42.667c0-11.776 4.736-22.4 12.501-30.165s18.389-12.501 30.165-12.501h170.667c11.776 0 22.4 4.736 30.165 12.501s12.501 18.389 12.501 30.165v42.667zM384 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667zM554.667 469.333v256c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-256c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667z"></path>
                </svg>
              </div>
            )
          })}
        </div>
        <div className="admin-home-container3">
          <h1 className="admin-home-text13">
            <span>Weekly Update</span>
            <br></br>
          </h1>
          <div className="admin-home-separator4"></div>
          <span className="admin-home-text16">
            <span className="user-home-text17">No Available Information at this time</span>
            {/* <span className="admin-home-text17">Week of 05/8/23</span>
            <br className="admin-home-text18"></br>
            <br></br>
            <span className="admin-home-text20">Key</span>
            <br></br>
            <span>* = Completed</span>
            <br></br>
            <span>~ = In Progress</span>
            <br></br>
            <br></br>
            <span>Slowmode ~ (Started on 5/22/23 @ 11:20 PM)</span>
            <br></br>
            <span>Emoji Id</span>
            <br></br>
            <span>8-Ball ~ (Started on 5/22/23 @ 11:20 PM)</span>
            <br></br>
            <span>Heads or Tails &quot;Coin Flip&quot;</span>
            <br></br>
            <span>Stats (Shows Bot Stats)</span>
            <br></br>
            <br></br>
            <span className="admin-home-text38">Daiki Only Commands</span>
            <br className="admin-home-text39"></br>
            <span>Post *</span>
            <br></br>
            <span>    ∙ create-bot (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ create-week (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ delete-bot (Completed 5/3/23)</span>
            <br></br>
            <span>    ∙ delete-week (Completed 5/3/23)</span>
            <br></br>
            <br></br>
            <span className="admin-home-text38">Daiki Website</span>
            <br className="admin-home-text39"></br>
            <br></br>
            <span>    ∙ Create Limit to Posts shows</span>
            <br></br>
            <span>    ∙ Create Create Delete Function for posts (ADMIN ONLY)</span>
            <br></br> */}
          </span>
        </div>
      </div>
      <div className="admin-home-container4">
        <div className="admin-home-container5">
          <h1 className="admin-home-text50">
            <span>Upcoming Updates</span>
            <br></br>
          </h1>
          <div className="admin-home-separator5"></div>
          <span className="admin-home-text53">
            <span>Daiki V14.4</span>
            <br></br>
          </span>
          <span className="admin-home-text56">
            No Available Information at this time
          </span>
        </div>
      </div>
      <Footer />
    </div>
  )
}
