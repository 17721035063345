import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './admin-header.css'

const AdminHeader = (props) => {
    function Logout() {
        window.localStorage.clear();
        window.location.href = '/';
    }
    return (
        <header data-role="Header" className="admin-header-admin-header">
            <img
                alt={props.image_alt}
                src={props.image_src}
                className="admin-header-image"
            />
            <div className="admin-header-nav">
                <nav className="admin-header-nav1">
                    <Link to="/" className="admin-header-navlink">
                        {props.text}
                    </Link>
                </nav>
                <nav className="admin-header-nav2">
                    <Link to="/updates" className="admin-header-navlink1">
                        {props.text1}
                    </Link>
                </nav>
                <nav className="admin-header-nav3">
                    <Link to="/create-post" className="admin-header-navlink2">
                        {props.text2}
                    </Link>
                </nav>
            </div>
            <div className="admin-header-btn-group">
                <button className="admin-header-login button" onClick={Logout}>{props.Login}</button>
            </div>
        </header>
    )
}

AdminHeader.defaultProps = {
    image_src: '/playground_assets/copy_of_animated_logo-200h.png',
    image_alt: 'logo',
    Login: 'Logout',
    text: 'Home',
    text1: 'Updates',
    text2: 'Post',
}

AdminHeader.propTypes = {
    image_src: PropTypes.string,
    image_alt: PropTypes.string,
    Login: PropTypes.string,
    text: PropTypes.string,
    text1: PropTypes.string,
    text2: PropTypes.string,
}

export default AdminHeader
