import React, { Component, useEffect, useState } from "react";
import AdminHome from "./admin-home";
import { ToastContainer, toast } from "react-toastify";

import UserHome from "./user-home";
import Login from "./login";

export default function UserDetails() {
    const [userData, setUserData] = useState("");
    const [admin, setAdmin] = useState(false);
    const [cantShow, setCantShow] = useState(false);
    const [isLoading, setLoading] = useState("");

    useEffect(() => {
        setLoading(true);
        fetch("https://daiki-login-api.vercel.app/userData", {
            method: "POST",
            crossDomain: true,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify({
                token: window.localStorage.getItem("token"),
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error('Failed to fetch!');
                }
                return res.json();
            })
            .then((data) => {
                setCantShow(false);
                setLoading(false)
                console.log(data, "userData");
                if (data.data.userType === "Admin") {
                    setAdmin(true);
                }
                setUserData(data.data);
                if (data.data === "token expired") {
                    window.localStorage.clear();
                    return (window.location.href = "/login");
                }
            })
            .catch((error) => {
                console.log(error);
                // Display error message using toast
                setCantShow(true);
                setLoading(false)
                toast.error('Unable to fetch user data at this time. Please try again.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            });
    }, []);

    return (
        <div>
            {isLoading ? (
                <span style={{ color: "white", fontWeight: "bold" }}>Loading...</span>
            ) : cantShow ? (
                <Login />
            ) : (
                admin ? (
                    <AdminHome userData={userData} />
                ) : (
                    <UserHome userData={userData} />
                )
            )}
            <ToastContainer />
        </div>
    );
}
